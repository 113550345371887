import styled, {css, keyframes} from 'styled-components';
import {Theme} from '../../theme/Theme';

export interface VideoPlayerWrapperProps {
  /** Css height value */
  height?: string;
}

export const VideoPlayerWrapper = styled.div<VideoPlayerWrapperProps>`
  position: relative;
  width: 100%;
  height: ${({height}) => (height ? height : '52vw')};
  max-height: 624px;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

interface ActivatePlayerButtonProps {
  videoId: string;
  show: boolean;
}

const animateButton = keyframes`
  50% {
    opacity: 0.9;
  }
  99% {
    height: 100%;
  }
  to {
    opacity: 0;
    cursor: not-allowed;
    pointer-events: none;
    height: 0;
  }
`;

const animatePlayButton = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(2);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Theme.colours.black};
`;

export const ActivatePlayerButton = styled.div<ActivatePlayerButtonProps>`
  width: 100%;
  height: 100%;
  background-color: ${Theme.colours.black}; /* fallback color */
  background: url('https://img.youtube.com/vi/${({videoId}) =>
      videoId}/maxresdefault.jpg')
    center;
  background-size: cover;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  :after {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background 0.4s ease-out;
    background: rgba(0, 0, 0, 1);
    content: ' ';
  }

  svg {
    z-index: 2;
    transition: transform 0.4s ease-out;
    opacity: 1;
  }

  ${({show}) =>
    show
      ? css`
          :after {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: background 0.3s ease-out;
            background: rgba(0, 0, 0, 0.3);
            content: ' ';
          }
          :hover {
            :after {
              background: rgba(0, 0, 0, 0.6);
            }
            svg {
              transform: scale(1.1);
            }
          }
        `
      : css`
          animation: ${animateButton} 1400ms ease-out forwards;

          svg {
            animation: ${animatePlayButton} 400ms ease-out forwards;
          }
        `}
`;
