import React, {FunctionComponent} from 'react';
import {IconProps} from './IconTypes';
import {Theme} from '../../theme/Theme';

export const PlayIcon: FunctionComponent<IconProps> = ({
  colour = Theme.colours.white,
  width = '24px',
  height = '24px',
  tabIndex,
}) => (
  <svg
    tabIndex={tabIndex}
    height={height}
    width={width}
    viewBox="0 0 24 24"
    fill={colour}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z" />
  </svg>
);
