import React, {FunctionComponent, useState} from 'react';
import YouTubePlayer from 'youtube-player';
import {
  VideoPlayerWrapper,
  ActivatePlayerButton,
  VideoPlayerWrapperProps,
  Placeholder,
} from './VideoPlayer.styles';
import {PlayIcon} from '../Icon/PlayIcon';
import {Theme} from '../../theme/Theme';

export interface VideoPlayerProps extends VideoPlayerWrapperProps {
  /** The YouTube video ID */
  videoID: string;
  /** the unique player ID */
  playerID: string;
  /** used in for testing */
  testID?: string;
  tabIndex?: number;
  showCloseButton?: boolean;
  handleClose?: () => void;
}

export const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  videoID,
  playerID,
  testID,
  tabIndex,
  showCloseButton,
  handleClose,
  ...props
}) => {
  const [activated, setActivated] = useState(false);
  const handlePlay = () => {
    YouTubePlayer(playerID, {
      videoId: videoID,
      playerVars: {
        autoplay: 1,
        modestbranding: 1, // https://developers.google.com/youtube/player_parameters#modestbranding
        rel: 0, // https://developers.google.com/youtube/player_parameters#rel
      },
    });
    setActivated(true);
  };
  return (
    <VideoPlayerWrapper {...props} data-testid={testID}>
      {showCloseButton && <button id="exit" onClick={handleClose}></button>}
      <ActivatePlayerButton
        show={!activated}
        videoId={videoID}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handlePlay();
          }
        }}
        onClick={handlePlay}
      >
        <PlayIcon
          width={`${Theme.space[6]}px`}
          height={`${Theme.space[6]}px`}
          tabIndex={tabIndex}
        />
      </ActivatePlayerButton>
      <Placeholder id={playerID} />
    </VideoPlayerWrapper>
  );
};
